// import React, { useState, useEffect } from 'react';
// import { FaFacebook, FaTwitter, FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';

// export default function HeroSection() {
//     const textArray = [
//         'a CS Grad!',
//         'a Technophile',
//         'into Machine Learning',
//         'into Frontend Development',
//         'into Backend Development',
//         'into Mobile App Development'
//     ];

//     const [currentWordIndex, setCurrentWordIndex] = useState(0);

//     useEffect(() => {
//         const intervalId = setInterval(() => {
//             setCurrentWordIndex((prevIndex) => (prevIndex + 1) % textArray.length);
//         }, 1000);

//         return () => clearInterval(intervalId);
//     }, []);

//     const handleResumeClick = () => {
//         window.open('https://drive.google.com/file/d/1-1HdRZMgOtlOYFtWCkiClQkSB7IU_Odl/view?usp=sharing', '_blank'); 
//     };

//     return (
//         <section id="heroSection" className="hero--section">
//             <div className="hero--section--content--box">
//                 <div className="hero--section--content">
//                     <h1 className="section--title" style={{ color: "#283D7B" }}>Hey,</h1>
//                     <h1 className="hero--section--title">
//                         <span style={{ color: "#283D7B" }}>I'm </span>
//                         <span style={{ color: "#AA37AF" }}>Arshiya Naheed.</span>
//                     </h1>
//                     <h3 style={{ color: "#283D7B" }}>
//                         <span>I'm </span>
//                         <span style={{ color: "#AA37AF" }}>{textArray[currentWordIndex]}</span>
//                     </h3>
//                     <p className="hero--section--description">  
//                     I'm a master's in Computer Science at Chico State. I'm all about making machines smarter through machine learning and piecing together websites and apps from scratch.
//                         <br />
//                         Away from the keyboard, I like to get creative with my drawings and enjoy baking. I'm ready to bring my blend of creativity and tech skills to a team that loves to think outside the box as much as I do.

//                     </p>
//                     <button onClick={handleResumeClick} className="btn btn-primary">My Resume</button>
//                     {/* <hr className="line-divider" /> */}

//                     <div className="social-icons"> {/* Container for social icons */}
//                         <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
//                             <FaGithub size={40} className="social-icon" />
//                         </a>
//                         <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
//                             <FaEnvelope size={40} className="social-icon" />
//                         </a>
//                         <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
//                             <FaLinkedin size={40} className="social-icon" />
//                         </a>
//                     </div>
//                 </div>
//             </div>
//             <div className="hero--section--img">
//                 <img src="./img/myprofile-pic.jpg" alt="Profile picture" />
//             </div>
//         </section>
//     );
// }


import React, { useState, useEffect } from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import Preloader from './Pre/Preloader';
import AOS from 'aos'; // Importing AOS library
import 'aos/dist/aos.css'; // Ensure the CSS file is imported

export default function HeroSection() {
    const textArray = [
        'a CS Grad!',
        'a Technophile',
        'into Machine Learning',
        'into Frontend Development',
        'into Backend Development',
        'into Mobile App Development'
    ];

    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % textArray.length);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        AOS.init({
            duration: 1000, // Default duration for animations
            easing: 'ease-in-out',
            offset: 100,
            once: true, // Animation should happen only once
        });

        // Refresh AOS animations after the component is updated
        AOS.refresh();
    }, []);

    const handleResumeClick = () => {
        window.open('https://drive.google.com/file/d/1-1HdRZMgOtlOYFtWCkiClQkSB7IU_Odl/view?usp=sharing', '_blank');
    };

    return (
        <section id="heroSection" className="hero--section">
            <Preloader />
            <div className="hero--section--content--box">
                <div className="hero--section--content" data-aos="fade-up" data-aos-delay="200">
                    <h1 className="section--title" style={{ color: "#283D7B" }}>Hey,</h1>
                    <h1 className="hero--section--title">
                        <span style={{ color: "#283D7B" }}>I'm </span>
                        <span style={{ color: "#AA37AF" }}>Arshiya Naheed</span>
                    </h1>
                    <h3 style={{ color: "#283D7B" }}>
                        <span>I'm </span>
                        <span style={{ color: "#AA37AF" }}>{textArray[currentWordIndex]}</span>
                    </h3>
                    <p className="hero--section--description">
                        I'm a master's in Computer Science at Chico State. I enjoy making machines smarter through machine learning and building websites and apps from scratch.
                        <br />
                        <br />
                        Away from the keyboard, I like to get creative with my drawings and enjoy baking. I'm ready to bring my blend of creativity and tech skills to a team that thinks outside the box.
                    </p>
                    <div className="button-and-icons" data-aos="fade-up" data-aos-delay="300">
                        <button onClick={handleResumeClick} className="btn btn-primary">My Resume</button>
                        <div className="social-icons"> {/* Container for social icons */}
                            <a href="https://www.github.com" target="_blank" rel="noopener noreferrer">
                                <FaGithub size={40} />
                            </a>
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                <FaTwitter size={40} />
                            </a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={40} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero--section--img">
                <img src="./img/myprofile-pic.jpg" alt="Profile picture" class="normal-image" />
                <img src="./img/my-second-pic.png" alt="Profile picture on hover" class="hover-image" />
            </div>

        </section>
    );
}

