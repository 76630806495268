// import data from "../../data/index.json"; // Ensure this path is correct

// export default function MyPortfolio() {
//     return (
//         <section className="portfolio--section" id="MyPortfolio">
//             <div className="portfolio--container--box">
//                 <div className="portfolio--container">
//                     <p className="sub--title">Projects</p>
//                     <h2 className="section--heading">My Portfolio</h2>
//                 </div>
//             </div>
//             <div className="portfolio--section--container">
//                 {data?.portfolio?.map((item, index) => ( // Fixed naming inconsistency
//                     <div key={index} className="portfolio--section--card">
//                         <div className="portfolio--section--img">
//                             <img src={item.src} alt="Placeholder" /> {/* Ensure src is correct */}
//                         </div>
//                         <div className="portfolio--section--card--content">
//                             <div>
//                                 <h3 className="portfolio--section--title">{item.title}</h3>
//                                 <p className="text-md">{item.description}</p>
//                             </div>
//                             <p className="text-sm portfolio--link">
//                                 <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     width="16"
//                                     height="16"
//                                     viewBox="0 0 20 19"
//                                     fill="none"
//                                 >
//                                     <path
//                                         d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z"
//                                         stroke="currentColor"
//                                         stroke-width="2.66667"
//                                         stroke-linecap="round"
//                                         stroke-linejoin="round"
//                                     />
//                                 </svg>
//                             </p>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </section>
//     );
// }


// import React, { useState } from "react";
// import data from "../../data/index.json";

// export default function MyPortfolio() {
//   // State to track which card is hovered
//   const [hoveredCard, setHoveredCard] = useState(null);

//   return (
//     <section className="portfolio--section" id="MyPortfolio">
//       <div className="portfolio--container--box">
//         <div className="portfolio--container">
//           {/* <p className="sub--title">Projects</p> */}
//           <h2 className="portfolio--section--heading">My Portfolio</h2>
//         </div>
//       </div>
//       <div className="portfolio--section--container">
//         {data?.portfolio?.map((item, index) => (
//           <div
//             key={index}
//             className="portfolio--section--card"
//             onMouseEnter={() => setHoveredCard(index)} // Track hover
//             onMouseLeave={() => setHoveredCard(null)} // Reset on hover end
//           >
//             <div className="portfolio--section--img">
//               <img src={item.src} alt={item.title} style={{ display: hoveredCard === index ? 'none' : 'block' }} /> {/* Hide image on hover */}
//             </div>
//             <div className="portfolio--section--card--content">
//               {hoveredCard === index ? ( // Change content on hover
//                 <>
//                   <h3 className="portfolio--section--title">{item.title}</h3>
//                   <p className="text-md">{item.hoverDescription}</p>
//                   <p className="text-md">{item.overview}</p>
//                   <p className="text-md">{item.technologies}</p>
//                   <p className="text-sm portfolio--link"> {/* You can add any additional content here */}
//                     View on GitHub
//                   </p>
//                 </>
//               ) : (
//                 <>
//                   <h3 className="portfolio--section--title">{item.title}</h3>
//                   <p className="text-md">{item.description}</p>
//                   <p className="text-sm portfolio--link">View on GitHub</p>
//                 </>
//               )}
//             </div>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// }

import React, { useState } from "react";
import data from "../../data/index.json";

export default function MyPortfolio() {
  // State to track which card is hovered
  const [hoveredCard, setHoveredCard] = useState(null);

  // Split data into two rows of three cards each
  const firstRow = data?.portfolio?.slice(0, 3); // First three items
  const secondRow = data?.portfolio?.slice(3, 6); // Next three items

  return (
    <section className="portfolio--section" id="MyPortfolio">
      <div className="portfolio--container--box">
      <div className="portfolio--container">
        <h2 className="portfolio--section--heading">My Work</h2> {/* Centered title */}
      </div>
      </div>
      {/* First row */}
      <div className="portfolio--section--container">
        {firstRow?.map((item, index) => (
          <div
            key={index}
            className="portfolio--section--card"
            onMouseEnter={() => setHoveredCard(index)} // Track hover
            onMouseLeave={() => setHoveredCard(null)} // Reset on hover end
          >
            {/* Content when not hovered */}
            {hoveredCard !== index ? (
              <>
                <div className="portfolio--section--img">
                  <img src={item.src} alt={item.title} /> 
                </div>
                <div className="portfolio--section--card--content">
                  <h3 className="portfolio--section--title">{item.title}</h3>
                  <p className="text-md">{item.description}</p>
                  {/* <p className="text-sm portfolio--link">View on GitHub</p> */}
                </div>
              </>
            ) : (
              <>
                {/* Content when hovered */}
                <div className="portfolio--section--card--content">
                  <h3 className="portfolio--section--title">{item.title}</h3>
                  <p className="text-md">{item.hoverDescription}</p>
                  <p className="text-md">{item.overview}</p>
                  <p className="text-md">{item.technologies.join(" ")}</p>
                  {/* <p className="text-sm portfolio--link">View on GitHub</p> */}
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Second row */}
      <div className="portfolio--section--container">
        {secondRow?.map((item, index) => (
          <div
            key={index + 3} // Adjusting index for the second row
            className="portfolio--section--card"
            onMouseEnter={() => setHoveredCard(index + 3)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            {/* Content when not hovered */}
            {hoveredCard !== index + 3 ? (
              <>
                <div className="portfolio--section--img">
                  <img src={item.src} alt={item.title} />
                </div>
                <div className="portfolio--section--card--content">
                  <h3 className="portfolio--section--title">{item.title}</h3>
                  <p className="text-md">{item.description}</p>
                </div>
              </>
            ) : (
              <>
                {/* Content when hovered */}
                <div className="portfolio--section--card--content">
                  <h3 className="portfolio--section--title">{item.title}</h3>
                  <p className="text-md">{item.hoverDescription}</p>
                  <p class="text-md">{item.overview}</p>
                  <p className="text-md">{item.technologies.join(" ")}</p>
                  {/* <p className="text-sm portfolio--link">View on GitHub</p> */}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}
