import React from 'react';
import './skills.css';

const SkillCard = ({details}) =>
{
    return (
        <div className='skill-experience-card'>
            <h6>{details.title}</h6>
            <ul>
                {details.description.map((item)=>
            (
                <li key={item}>{item}</li>
            ))}
            </ul>
        </div>
    )
}

export default SkillCard