import React from "react";
// import Card from './Card';

const Card = (props) => {
    return (
        <div className="timeline__item">
            <i className={props.icon}></i>
            <span className="span timeline__date">
                    {props.year}
                </span>
                <h3 className="timeline__title">
                    {props.title}
                </h3>
                <h4 className="timeline__place">{props.place}</h4>
                <h4 className="timeline__location">{props.location}</h4>
                <p className="timeline__text">
                    {props.desc}
                </p>

        </div>
    )
}

export default Card 