 export const SKILL_EXPERIENCE= [
    {
        "title": "Programming",
        "description": ["C++", "Python", "Dart", "JavaScript"],
    },
    {
        "title": "Web Technologies",
        "description": ["ReactJS", "HTML", "CSS", "Flutter", "Bootstrap"],
    },
    {
        "title": "Database",
        "description": ["MySQL", "MongoDB", "Firebase"],
    },
    {
        "title": "Machine-Learning Libraries",
        "description": [
            "Scikit-Learn",
            "TensorFlow",
            "PyTorch",
            "Pandas",
            "Numpy",
            "Matplotlib",
            "LLM",
            "RAG",
            "Hugging Faces"
        ]
    },
    {
        "id": "5",
        "title": "Tools and Frameworks",
        "description": ["Git", "Figma", "PowerBi"],
    },
    {
        "id": "6",
        "title": "Mobile-App Development",
        "description": ["Flutter", "React Native"],
    },
];