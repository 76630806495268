import { useState, useEffect } from "react";
import { Link } from "react-scroll";


function Navbar() {
    
    const [navActive, setNavActive] = useState(false);
    // const [navBackground, setNavBackground] = useState('transparent');

    //function which opens the Navbar
    const togglenav = () => { setNavActive(!navActive) };

    //function which close the Navbar
    const closemenu = () => { setNavActive(false) };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 500) {
                closemenu;
            }
        };

        window.addEventListener("resize", handleResize);
        return () => { window.removeEventListener("resize", handleResize); };

    }, []);

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            closemenu;
        }
    }, []);

    return (
        <nav className={`navbar ${navActive ? "active" : ""}`}>
            {/* <div>
                <img src="./img/mylogo.png" alt="logo" />
            </div> */}
            <a className={`nav__hamburger ${navActive ? "active" : ""}`} onClick={togglenav}>
                <span className="nav__hamburger__line"></span>
                <span className="nav__hamburger__line"></span>
                <span className="nav__hamburger__line"></span>
            </a>
            <div className={`navbar--items ${navActive ? "active" : ""}`}>
                <ul>
                    <li>
                        <Link onClick={closemenu} 
                        activeClass="navbar--active-content" 
                        spy={true} smooth={true} 
                        offset={-70} duration={500} to="heroSection" 
                        className="navbar--contentt">  
                        Home</Link>
                    </li>

                    <li>
                        <Link onClick={closemenu} 
                        activeClass="navbar--active-content" 
                        spy={true} smooth={true} 
                        offset={-70} duration={500} to="resume" 
                        className="navbar--contentt">  
                        Experience </Link>
                    </li>
                    <li>
                        <Link onClick={closemenu} 
                        activeClass="navbar--active-content" 
                        spy={true} smooth={true} 
                        offset={-70} duration={500} to="skillsexpri" 
                        className="navbar--contentt">  
                        Skills</Link>
                    </li>
                    <li>
                        <Link onClick={closemenu} 
                        activeClass="navbar--active-content" 
                        spy={true} smooth={true} 
                        offset={-70} duration={500} to="MyPortfolio" 
                        className="navbar--contentt">  
                        Projects</Link>
                    </li>

                </ul>
            </div>
            

        </nav>
    )
}

export default Navbar;