import HeroSection from "../HeroSection";
import MyPortfolio from "../MyPortfolio";
import MySkills from "../MySkills";
import Footer from "../Footer";
import Resume from "../Resume";
import SkillsExperience from "../skills/SkillsExperience";
import Preloader from "../Pre/Preloader";

export default function Home(){
    return (
        <>
        <Preloader></Preloader>
        <HeroSection />
        <Resume></Resume>
        <SkillsExperience/>
        <MyPortfolio/>
        <Footer></Footer>
        </>
    )
}