import React, { useRef } from 'react';
import { useEffect } from 'react';
import './skills.css';
import SkillCard from './skillcard';
import { SKILL_EXPERIENCE } from './sdata';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'material-symbols';

const SkillsExperience = () => {
    const sliderRef = useRef();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        // Setting a longer duration for a slower transition
        AOS.init({
            duration: 2000, // 3000ms = 3 seconds
            easing: 'ease-in-out', // Adjust as needed
            offset: 100 // Optional: when to start the animation (100px from the viewport)
        });
    }, []);
    const slideRight = () => {
        sliderRef.current.slickNext();
    };

    const slideLeft = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <section className="experience-container" id='skillsexpri'>
            <h5>Skills</h5>
            <div className="experience-content" >
                <div className="arrow-right" onClick={slideRight}>
                    <span className="material-symbols-outlined">chevron_right</span>
                </div>
                <div className="arrow-left" onClick={slideLeft}>
                    <span className="material-symbols-outlined">chevron_left</span>
                </div>

                <Slider ref={sliderRef} {...settings}>
                    {SKILL_EXPERIENCE.map((item) => (
                        <SkillCard key={item.title} details={item} />
                    ))}
                </Slider>
            </div>
        </section>
    )
}

export default SkillsExperience