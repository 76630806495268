// import React from 'react';
// import data from "../../data/index.json"; // Make sure this is the correct path and import
// import Card from './card';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Resume = () => {
//     return (
//         <section className='resume container section' id='resume'>
// <h1 className="r--heading">Experience</h1>
//             <div className="resume__container grid" data-aos="fade-in">
//                 <div className="timeline" >
//                     {data.Experience.map((val, id) => { 
//                         if (val.category === "Education") {
//                             return (
//                                 <Card
//                                     key={id}
//                                     icon={val.icon}
//                                     title={val.title}
//                                     year={val.year}
//                                     place={val.place}
//                                     location={val.location}
//                                     desc={val.description}
//                                 />
//                             )
//                         }
//                         return null;
//                     })}
//                 </div>
//                 <div className="timeline">
//                     {data.Experience.map((val, id) => { 
//                         if (val.category === "Experience") {
//                             return (
//                                 <Card
//                                     key={id}
//                                     icon={val.icon}
//                                     title={val.title}
//                                     year={val.year}
//                                     place={val.place}
//                                     location={val.location}
//                                     desc={val.description}
//                                 />
//                             )
//                         }
//                         return null;
//                     })}
//                 </div>
//             </div>
//         </section>
//     )
// }

// export default Resume;




import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Ensure the CSS file is imported
import data from "../../data/index.json"; 
import Card from './card';

const Resume = () => {
    useEffect(() => {
        // Setting a longer duration for a slower transition
        AOS.init({ 
            duration: 2000, // 3000ms = 3 seconds
            easing: 'ease-in-out', // Adjust as needed
            offset: 100 // Optional: when to start the animation (100px from the viewport)
        });
    }, []);

    return (
        <section className='resume container section' id='resume'>
            <h1 className="r--heading">Experience</h1>
            <div className="resume__container grid" data-aos="fade-in">
                <div className="timeline">
                    {data.Experience.map((val, id) => {
                        if (val.category === "Education") {
                            return (
                                <Card
                                    key={id}
                                    icon={val.icon}
                                    title={val.title}
                                    year={val.year}
                                    place={val.place}
                                    location={val.location}
                                    desc={val.description}
                                />
                            );
                        }
                        return null;
                    })}
                </div>
                <div className="timeline">
                    {data.Experience.map((val, id) => {
                        if (val.category === "Experience") {
                            return (
                                <Card
                                    key={id}
                                    icon={val.icon}
                                    title={val.title}
                                    year={val.year}
                                    place={val.place}
                                    location={val.location}
                                    desc={val.description}
                                />
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </section>
    );
};

export default Resume;
