import { Link } from "react-scroll";
import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa'; // Importing FontAwesome icons

function Footer() {
  return (
    <footer className="footer--container">
      <div className="footer--link--container">
        <div className="footer--social--icon">
          <ul>
            <li>
              <a href="https://www.linkedin.com/in/arshiya-naheed-70956418a/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={32} className="social-icon" />
              </a>
            </li>
            
            <li>
              <a href="https://github.com/arshiya19" target="_blank" rel="noopener noreferrer">
                <FaGithub size={32} className="social-icon" />
              </a>
            </li>
            <li>
              <a href="mailto:arshiyanaheed98@gmail.com">
                <FaEnvelope size={32} className="social-icon" />
              </a>
            </li>

          </ul>
        </div>
      </div>
      <div className="footer--content--container">
        <p className="footer--content">© Copyright 2024 Arshiya Naheed</p>
      </div>
    </footer >
  );
}

export default Footer;